.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
}

#particles canvas {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(120deg, #623d7b,#080130)
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  width: 100vw;
  height: 100vh;
}

.name-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  flex-direction: column;
  
}

.name-container h1 {
  font-size: 10vw;
  text-align: center;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(342deg, #FFFFFF, #FFFFFF);
  overflow: hidden;
}
.name-container h2 {
  font-size: 8vw;
  text-align: center;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(342deg, #FFFFFF, #FFFFFF);
  overflow: hidden;
}

.title-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin-top: 7vw;
  pointer-events: none;
  overflow: hidden;
  flex-direction: column;
}

.title-container p {
  font-size: 4vw;
  text-align: center;
  text-decoration: none;
  pointer-events: all;
  color: #FFFFFF;
}

.links-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  width: 100%;
  height: 100%;
  margin-top: 12vw;
  gap: 20px;
  overflow: hidden;
  
}


.links-container a {
  font-size: 3vw;
  text-align: center;
  text-decoration: underline;
  pointer-events: all;
  color: #FFFFFF;
  align-items: center;

}

.links-container svg{
  font-size: 1.25vw;
  color: #ffffff37;
}

.links-container a:hover {
  text-decoration: underline;
  color: #d17474;
}